<template>
    <div>
        <v-card>
            <v-img
            :src="prikazovalnik"
            class="white--text align-end"
            aspect-ratio="4.88"
            v-if="semafor.stanje != 0 && semafor.stanje != 1"
            ></v-img>

            <!--<v-card-title>-->
                <v-card-title primary-title>
            <div>
                <div class="semafor-title font-weight-regular"> {{ this.semafor.naziv }}</div>
                <!--<span class="grey--text">{{ this.tabla }}</span>-->
            </div>
            <v-spacer></v-spacer>
            <!--<v-btn icon @click="show = !show">
                <v-icon>{{ show ? 'keyboard_arrow_down' : 'keyboard_arrow_up' }}</v-icon>
            </v-btn>-->
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text v-show="show" id="card_semafor_body">
                <v-container fluid fill-height text-xs-center>
                    <v-layout row wrap>
                        <v-flex xs12 sm12>
                            <!--<v-switch :label="`Način delovanja: ${naicnDelovanja()}`" v-model="semafor.nacin"></v-switch>-->
                            <v-select
                            label="Izberi način delovanja"
                            item-text="naziv"
                            item-value="id_nacin"
                            v-model="nacin"
                            :items="nacini"
                            ></v-select>
                        </v-flex>
                        <v-flex xs12 sm12>
                            <v-select
                            label="Izberi stanje zgornjega dela table"
                            item-text="naziv"
                            item-value="id_stanje"
                            v-model="stanje"
                            :items="stanja"
                            :disabled="semafor.nacin == 1"
                            ></v-select>
                        </v-flex>

                        <v-flex xs12 sm12>
                            <v-text-field
                                v-model="tekst"
                                label="Vpišite poljubno besedilo za prikaz na spodnjem delu table"
                                counter
                                clearable
                                outlined
                                placeholder="... ... ..."
                            >
                            </v-text-field>
                        </v-flex>

                        <br>

                        <p>Opomba: na spodnjem delu table tekst ni prikazan le v primeru, če je vpisno polje za pojlubno besedilo prazno.</p>

                    </v-layout>
                </v-container>
            </v-card-text>
            <v-divider></v-divider>
            <div v-show="show">
                <v-card-actions v-if="!$vuetify.breakpoint.xsOnly">
                    <v-btn :loading="submitLoading" @click="submit()" text :disabled="cancelLoading"><v-icon left>mdi-arrow-right</v-icon>Shrani</v-btn>
                    <v-btn :loading="cancelLoading" @click="cancel()" text color="purple" :disabled="submitLoading"><v-icon left>mdi-refresh</v-icon>Prekliči</v-btn>
                </v-card-actions>
                <v-card-text v-else>
                    <v-btn :loading="submitLoading" @click="submit()" block outlined  style="margin-bottom:10px;" :disabled="cancelLoading"><v-icon left>mdi-arrow-right</v-icon>Shrani</v-btn>
                    <v-btn :loading="cancelLoading" @click="cancel()" block outlined  style="margin-bottom:10px;" color="purple" :disabled="submitLoading"><v-icon left>mdi-refresh</v-icon>Prekliči</v-btn>
                </v-card-text>
            </div>
        </v-card>
        <br>
    </div>
</template>

<script>
import semaforUgasnjen from '@/assets/semaforji/tabla_1_red.png'
import semaforProsto from '@/assets/semaforji/tk_prosto.svg'
import semaforZasedeno from '@/assets/semaforji/tk_zasedeno.svg'

export default {
    name: 'semafor',
    props: ['semafor', 'tabla'],

    data: () => ({
        show: true,
        cancelLoading: false,
        submitLoading: false,
        nacin: null,
        stanje: null,
        tekst: ""
    }),

    watch: {
        nacin: function(value) {
            this.semafor.nacin = value
        },

        stanje: function(value) {
            this.semafor.stanje = value
        }, 

        tekst: function(value) {
            this.semafor.tekst = value
        }
    },

    computed: {
        stanja() {
            //return this.$store.getters.stanja
            return this.semafor.stanja
        },

        nacini() {
            return this.$store.getters.nacini
        },

        semaforji() {
            return this.tabla.semaforji
        },

       
        prikazovalnik() {
            if(this.stanje == 0) {
                return semaforUgasnjen
            }

            if(this.stanje == 12) {
                return semaforProsto
            }

            return semaforZasedeno
        }
    },

    methods: {
        naicnDelovanja() {
            if(this.semafor.nacin) {
                return 'Manual'
            }

            return 'Auto'
        },

        naciniDelovanja() {
            if(this.semafor.nacin) {
                return {
                    id_nacin: 'Ročni',
                    naziv: true
                }
            }

            return {
                id_nacin: 'Avtomatski',
                naziv: false
            }
        },

        submit() {
            //console.log("SUBMIT")
            //console.log(this.semafor)
            var vm = this;
            vm.submitLoading = true;
            this.$store.dispatch('UPDATE_SEMAFOR', this.semafor)
            .then(response => {
                //console.log(response)

                setTimeout( () => {
                    vm.submitLoading = false;
                    vm.submitLoading = false;
                    vm.$store.commit('snackbarDisplay', false)
                    vm.$store.commit('snackbarText', 'Posodobitev semaforja ' + vm.semafor.naziv + ' je uspela.')
                    vm.$store.commit('snackbarDisplay', true)
                }, 500);
            })
            .catch(error => {
                console.log("semafor update ... error")
                //console.log(error)
                setTimeout( () => {
                    vm.submitLoading = false;
                    vm.submitLoading = false;
                    vm.$store.commit('snackbarDisplay', false)
                    vm.$store.commit('snackbarText', 'Posodobitev semaforja ' + vm.semafor.naziv + ' ni uspela.')
                    vm.$store.commit('snackbarDisplay', true)
                }, 500);
            })
        },

        cancel() {
           let vm = this;
           vm.cancelLoading = true;
           this.$store.dispatch('SEMAFOR', this.semafor.id_semafor)
           .then(response => {
               //window.console.log(response.data.data)
               this.nacin = response.data.data.nacin.id_nacin
               this.stanje = response.data.data.stanje.id_stanje
               this.tekst = response.data.data.tekst

               setTimeout(() => {
                   vm.cancelLoading = false
               }, 500)
           })
           .catch(error => {
               console.log("SEMAFOR error")
               console.log(error)
               setTimeout(() => {
                   vm.cancelLoading = false
               }, 500)
           })
        },
    },

    created() {
        //console.log("Semafor: " + this.id_semafor + " -- " + this.semafor.naziv)
        //window.console.log(this.semafor)
        this.nacin = this.semafor.nacin
        this.stanje = this.semafor.stanje
        this.tekst = this.semafor.tekst
    }
}
</script>

<style scoped>
    #img_semafor {
        margin-top:6px !important;
        height:40px;
    }

    .card_actions {
        height:50px !important;
    }

    .semafor-title {
        font-size: 18px!important;
    }

    #card_semafor_body {
        padding: 16px 16px 5px 16px !important;
    }
</style>

